import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link } from 'gatsby';
import "./_footer.scss";


const CustomFooter = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulFooter {
        nodes {
          footerLogo {
            file {
              url
            }
          }
          shippingLink
          copyright
          footerLink1 {
            name
            link
          }
          footerLink2 {
            name
            link
          }
          footerLink3 {
            title
            link
          }
          footerLink4 {
            title
            link
          }
          socialLink1 {
            title
            link
          }
          socialLink2 {
            title
            link
          }
        }
      }
    }
  `)

  const footerData = data.allContentfulFooter.nodes;

  // rendering components
  return (
    <>
      {footerData.map((data, index) =>
        <div className="footer-container" key={index}>
          <div className="footer-inner-container">
            <div className="footer-left-container">
              <div className="footer-menu-wrapper">
                <div className="footer-menu-inner-wrapper">
                  <Link className="footer-menu-link" to={data.footerLink1.link}>{data.footerLink1.name}</Link>
                  <Link className="footer-menu-link" to={data.footerLink2.link}>{data.footerLink2.name}</Link>
                </div>
                <div className="footer-menu-inner-wrapper">
                  <Link className="footer-menu-link" to={data.footerLink3.link}>{data.footerLink3.title}</Link>
                  <Link className="footer-menu-link" to={data.footerLink4.link}>{data.footerLink4.title}</Link>
                </div>
              </div>
              <div className="footer-help-wrapper">
                <a className="footer-help-item" onClick={() => {
                  scrollTo(`${data.shippingLink}`)
                }}>Shipping</a>
              </div>
            </div>
            <div className="footer-middle-container">
              <img
                src="/images/footer-logo.svg"
                className="footer-logo"
              />
            </div>
            <div className="footer-right-container">
              <div className="footer-social-link-wrapper">
                <a className="footer-social-menu" href={data.socialLink1.link}>{data.socialLink1.title}</a>
                <a className="footer-social-menu" href={data.socialLink2.link}>{data.socialLink2.title}</a>
              </div>
              <div className="footer-copyright-wrapper">
                <a className="footer-copyright-item">
                  {data.copyright}
                </a>
              </div>
            </div>
          </div>
          <div className="footer-mobile-container">
            <div className="footer-mobile-menu-container">
              <div className="footer-mobile-menu-wrapper">
                <Link className="footer-menu-link" to={data.footerLink1.link}>{data.footerLink1.name}</Link>
                <Link className="footer-menu-link" to={data.footerLink2.link}>{data.footerLink2.name}</Link>
                <Link className="footer-menu-link" to={data.footerLink3.link}>{data.footerLink3.title}</Link>
                <Link className="footer-menu-link" to={data.footerLink4.link}>{data.footerLink4.title}</Link>
              </div>
              <img
                src="/images/footer-logo.svg"
                className="footer-logo"
              />
            </div>
            <div className="footer-mobile-social-container">
              <div className="footer-social-link-wrapper">
                <a className="footer-social-menu" href={data.socialLink1.link}>{data.socialLink1.title}</a>
                <a className="footer-social-menu" href={data.socialLink2.link}>{data.socialLink2.title}</a>
              </div>
            </div>
            <div className="footer-mobile-help-container">
              <a className="footer-help-item" onClick={() => {
                scrollTo(`${data.shippingLink}`)
              }}>Shipping</a>
              <a className="footer-copyright-item">
                {data.copyright}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomFooter;