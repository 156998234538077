import React, { useState } from 'react';
import styled from "styled-components";
import HamburgerMenu from "react-hamburger-menu";
import { Link } from "gatsby";
import "./_header.scss";


// styled components
const NavMenu = styled.div`
  position: absolute;
  background: white;
  height: 150px;
  display: ${(props) => props.menuOpened ? "flex" : "none"};
  z-index: 10000;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  left: 1px;
  right: 1px;
`

const VisibleDesktop = styled.div`
  @media(min-width:600px){
    display:block;
  }
  @media(max-width:599px){
    display:none;
  }
`

const VisibleMobile = styled.div`
  @media(min-width:600px){
    display:none;
  }
  @media(max-width:599px){
    display:block;
  }
`

const CustomHeader = () => {

  // state
  const [menuOpened, setMenuOpend] = useState(false);

  // rendering components
  return (
    <div className="fixed-header">
      <div className="header-container">
        <a href="/"><img className="header-logo" src="/logo/header-logo.jpg" /></a>
        <VisibleDesktop>
          <div className="header-menu-wrapper">
            <Link className="menu-link" to="/products/all">product</Link>
            <Link className="menu-link" to="/brewery">brewery</Link>
            <Link className="menu-link" to="/about">about</Link>
          </div>
        </VisibleDesktop>
        <VisibleMobile>
          <HamburgerMenu
            isOpen={menuOpened}
            menuClicked={() => { setMenuOpend(!menuOpened) }}
            width={20}
            height={15}
            strokeWidth={2}
            rotate={0}
            color='black'
            borderRadius={0}
            animationDuration={1}
          />
        </VisibleMobile>
      </div >
      <NavMenu menuOpened={menuOpened} >
        <Link className="link-item" to="/products/all">product</Link>
        <Link className="link-item" to="/brewery">brewery</Link>
        <Link className="link-item" to="/about">about</Link>
      </NavMenu>
    </div>
  )
};

export default CustomHeader;