/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
// import { setConfiguration, ScreenClassProvider } from "react-grid-system";
import styled from "styled-components";
import './index.css';

// setConfiguration({
//   breakpoints: [375, 768, 992, 1440],
//   containerWidths: [370, 750, 960, 1440]
// });

const Wrapper = styled.div`
  overflow-x: hidden;
  max-width:100%;
  margin-left:auto;
  margin-right:auto;
`;
export default ({ children, id }) => {
  return (
    <Wrapper id={id}>
      {children}
    </Wrapper>
  );
};