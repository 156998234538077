/* eslint-disable react/prop-types */
import React from "react";
import "./_productListItem.scss";
import Img from 'gatsby-image';

const ProductListItem = ({ data, onClick }) => {
  if (!data) {
    return
  }
  if (data)
    return (
      <div className="product-item-container" onClick={() => {
        onClick();
      }}>
        <div className="product-title-wrapper">
          <div className="product-image-v-wrapper">
            <div className="product-image-h-wrapper">
              {
                data.image &&
                <Img
                  style={{ width: "100%", height: "100%" }}
                  fluid={data.image.fluid}
                  loading="lazy"
                  imgStyle={{
                    objectFit: "unset"
                  }}
                  alt="Product Image"
                />
              }
            </div>
          </div>
          {data.new ? (
            <div className="new-mark-wrapper">
              <div className="product-circle-2" />
              <div className="product-circle-1" />
              <div className="product-new-text">New</div>
            </div>
          ) : null}
          <div className="product-name-title">{data.title}</div>
        </div>
        <div className="product-info-wrapper">
          <div className="product-tag-name">{data.brewery}</div>
          <div className="product-volume-title">{data.alcohol_percentage}% by vol</div>
          <div className="product-splitter-line" />
          <div className="product-price-btn-wrapper">
            <div className="product-price-title">SEK{data.price} excl. VAT</div>
            {/* <div className="product-price-title">${data.price}</div> */}
            <div className="product-order-btn-wrapper">
              <div className="product-order-btn-text">
                Order
            </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ProductListItem;
