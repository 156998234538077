/* eslint-disable no-unused-vars */
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "./_containers.scss";


const FollowContainer = () => {
  const { instagramImages } = useStaticQuery(graphql`
  query{
    instagramImages:allInstaNode {
        nodes {
          localFile {
            childImageSharp {
              fluid(maxWidth: 280, maxHeight: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `);

  const onSlideChange = e => { };

  const onSlideChanged = e => { };

  const responsive = {
    0: {
      items: 8
    },
  };

  return (
    <div className="home-following-container">
      <div className="home-following-title-container">
        <div className="home-following-title-max">#spillagency</div>
        <div className="home-following-title-min">Follow us on instagram</div>
      </div>
      <div className="home-following-carousel-container">
        <AliceCarousel
          duration={400}
          dotsDisabled
          infinite={false}
          startIndex={0}
          fadeOutAnimation={true}
          mouseDragEnabled={true}
          playButtonEnabled={false}
          mouseTrackingEnabled={true}
          onSlideChange={onSlideChange}
          onSlideChanged={onSlideChanged}
          responsive={responsive}
          buttonsDisabled={true}
          stagePadding={{
            paddingLeft: 50,
            paddingRight: 50
          }}
        >
          {instagramImages.nodes.map((item, index) => {
            return (index % 2 === 1 && item.localFile) ? (
              <Img
                key={item.id}
                draggable={false}
                fluid={item.localFile.childImageSharp.fluid}
                onDragStart={e => e.preventDefault()}
                loading="lazy"
                width="280"
                height="280"
                className="home-following-high-img"
              />
            ) : (
                <Img
                  key={item.id}
                  fluid={item.localFile.childImageSharp.fluid}
                  draggable={false}
                  onDragStart={e => e.preventDefault()}
                  loading="lazy"
                  width="280"
                  height="280"
                  className="home-following-low-img"
                />
              );
          })}
        </AliceCarousel>
      </div>
    </div>
  );
};

export default FollowContainer;
