/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import Img from 'gatsby-image';
import * as Yup from "yup";
import SlidingPane from "react-sliding-pane";
import { IoMdArrowBack } from "react-icons/io";
import Modal from "react-modal";
import { Container, Row, Col } from "reactstrap";
import { createGlobalStyle } from "styled-components";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./_slidingPanel.scss";


const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.hidden ? "hidden" : "scroll")};
  }
`;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const OrderSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
})


const Index = ({ product, isInfoPaneOpen, setIsInfoPaneOpen }) => {
  const [isOrderPaneOpen, setIsOrderPaneOpen] = useState(false);
  const [isThankPaneOpen, setIsThankPaneOpen] = useState(false);

  useEffect(() => {
    Modal.setAppElement("#slidingPane");
  }, []);

  const handleSubmit = values => {

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "order", ...values }),
    })
      .then(() => {
        setIsOrderPaneOpen(false)
        setIsThankPaneOpen(true)
      })
      .catch(error => alert(error))

    console.log("submitted values :::::>", values)
  }

  if (!product) {
    return <div />
  }
  if (product)
    return (

      <>
        <GlobalStyle />
        <SlidingPane
          isOpen={isInfoPaneOpen}
          from="right"
          onRequestClose={() => setIsInfoPaneOpen(false)}
          className="product-sliding-pane"
          style={{ zIndex: "999" }}
        >
          <Container
            fluid
            style={{
              backgroundColor: "#FFFFFF",
              height: "100%",
              marginTop: "-24px",
              marginLeft: "-32px",
              marginRight: "-24px"
            }}
            className="product-sliding-pane-container"
          >
            <Row
              style={{
                position: "absolute",
                zIndex: "99",
                left: "36px",
                top: "25px",
                cursor: "pointer",
              }}
            >
              <Col>
                <IoMdArrowBack
                  style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
                  onClick={() => setIsInfoPaneOpen(false)}
                />
              </Col>
            </Row>
            <Row justify="center" align="center" style={{ padding: 0 }}>
              <Col style={{ padding: 0 }}>
                {product.image && (
                  <Img
                    fluid={product.image.fluid}
                    imgStyle={{
                      objectFit: "unset"
                    }}
                    loading="lazy"
                    className="product-slide-pane-img"
                  />
                )}
              </Col>
            </Row>
            <Row style={{ padding: 0 }}>
              <Col style={{ padding: 0 }}>
                <div className="sliding-product-price-text">
                  SEK {product.price}
                </div>
              </Col>
              <Col style={{ padding: 0 }}>
                <button className="sliding-product-order-btn" onClick={() => {
                  setIsInfoPaneOpen(false);
                  setIsOrderPaneOpen(true);
                }}>
                  <p className="sliding-product-order-btn-text">
                    Order
                  </p>
                </button>
              </Col>
            </Row>
            <Row style={{ padding: "26px" }}>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-max">{product.title}</div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-min">USA</div>
              </Col>
            </Row>
            <Row>
              <div className="sliding-product-splitter-line" />
            </Row>
            <Row style={{ padding: "26px" }}>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-mid">Type:</div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className='sliding-product-order-title-min'>{product.type}</div>
              </Col>
              <Col
                lg={12}
                xl={12}
                md={12}
                sm={12}
                style={{ padding: 0, marginTop: "16px" }}
              >
                <div className="sliding-product-order-title-mid">Brewery:</div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-min">{product.brewery}</div>
              </Col>
              <Col
                lg={12}
                xl={12}
                md={12}
                sm={12}
                style={{ padding: 0, marginTop: "16px" }}
              >
                <Row>
                  <Col>
                    <div className="sliding-product-order-title-mid">Alkohol:</div>
                  </Col>
                  <Col>
                    <div className="sliding-product-order-title-mid">Volym:</div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <Row>
                  <Col>
                    <div className="sliding-product-order-title-min">{product.alcohol_percentage}% by vol</div>
                  </Col>
                  <Col>
                    <div className="sliding-product-order-title-min">{product.packaging}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="sliding-product-splitter-line" />
            </Row>
            <Row style={{ padding: "26px" }}>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-mid">Product Description:</div>
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-min">
                  {product.description.description}
                </div>
              </Col>
            </Row>
          </Container>
        </SlidingPane>

        <SlidingPane
          isOpen={isOrderPaneOpen}
          from="right"
          onRequestClose={() => {
            setIsOrderPaneOpen(false);
            setIsInfoPaneOpen(true);
          }}
          className="product-sliding-pane"
        >
          <Container
            fluid
            style={{
              backgroundColor: "#FFFFFF",
              height: "90%",
              marginTop: "-24px",
              marginLeft: "-32px",
              marginRight: "-24px"
            }}
            className="pane-form-wrapper product-sliding-pane-container"
          >
            <Row
              style={{
                position: "absolute",
                zIndex: "99",
                left: "36px",
                top: "25px",
                cursor: "pointer",
              }}
            >
              <Col>
                <IoMdArrowBack
                  style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
                  onClick={() => {
                    setIsOrderPaneOpen(false);
                    setIsInfoPaneOpen(true);
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "44px",
                paddingLeft: "24px",
              }}
              className="form-row-wrap"
            >
              <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                <div className="sliding-product-order-title-max" style={{ maxWidth: "365px" }}>
                  Leave your{" "}
                  <div className="sliding-product-order-title-max" style={{ color: "#F7B4CC", display: "inline-block" }}>
                    phone number
                    </div>{" "}
                  or email address to order the product. We will contact you
                  as soon as possible.
                  </div>
              </Col>
            </Row>
            <Formik
              initialValues={{
                "bot-field": "",
                "form-name": "order",
                name: "",
                email: "",
                phone: "",
                product: product.title,
                volume: product.packaging,
              }}
              validationSchema={OrderSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form
                  className="order-form-wrap"
                  name="order"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >

                  <input
                    type="hidden"
                    name="form-name"
                    value="order"
                  />
                  <input name="product" value={product.title} style={{ display: "none" }} />
                  <input name="volume" value={product.packaging} style={{ display: "none" }} />
                  <Row style={{ padding: "0 24px 0 24px" }}>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0, marginTop: "16px" }}>
                      <div className="sliding-product-order-title-mid">Name / Company Name </div>
                    </Col>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                      <Field
                        className="name-field"
                        type="text"
                        name="name"
                        placeholder="Name / Company Name"
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "0 24px 0 24px" }}>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0, marginTop: "16px" }}>
                      <div className="sliding-product-order-title-mid">Phone Number</div>
                    </Col>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                      <Field
                        className="phone-field"
                        type="number"
                        name="phone"
                        placeholder="+4_"
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "0 24px 0 24px" }}>
                    <Col
                      lg={12}
                      xl={12}
                      md={12}
                      sm={12}
                      style={{ padding: 0, marginTop: "16px" }}
                    >
                      <div
                        className={errors.email && touched.email ? "email-required sliding-product-order-title-mid" : "sliding-product-order-title-mid"}
                      >
                        Email Address
                      </div>
                    </Col>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                      <Field
                        className={`email-field ${errors.email && touched.email ? "email-input-required" : ""}`}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "0 24px 0 24px" }}>
                    <Col
                      lg={12}
                      xl={12}
                      md={12}
                      sm={12}
                      style={{ padding: 0, marginTop: "16px" }}
                    >
                      <div className="sliding-product-order-title-mid">Message</div>
                    </Col>
                    <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                      <Field
                        className="message-field"
                        component="textarea"
                        name="message"
                        rows="5"
                        placeholder="Type your message"
                      />
                    </Col>
                  </Row>
                  <Row style={{ padding: "0 24px 0 24px" }}>
                    <Col
                      lg={12}
                      xl={12}
                      md={12}
                      sm={12}
                      style={{ padding: 0, marginTop: "30px" }}
                    >
                      <button
                        className="sliding-product-send-btn-wrapper"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <div className="sliding-product-send-btn-text">
                          Send Request
                        </div>
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </SlidingPane>

        <SlidingPane
          isOpen={isThankPaneOpen}
          from="right"
          onRequestClose={() => {
            setIsThankPaneOpen(false);
            setIsOrderPaneOpen(true);
          }}
          className="product-sliding-pane"
        >

          <Container>
            <Row
              style={{
                position: "absolute",
                zIndex: "99",
                // left: "9px",
                top: "25px",
                cursor: "pointer",
              }}
            >
              <Col>
                <IoMdArrowBack
                  style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
                  onClick={() => {
                    setIsThankPaneOpen(false);
                    setIsOrderPaneOpen(true);
                  }}
                />
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <img
                alt="Logo"
                src="/images/footer-logo.svg"
                className="product-slide-pane-logo-img"
              />
              <div className="sliding-product-order-title-max" style={{ marginTop: "32px" }}>Thank you for request!</div>
              <div className="sliding-product-order-title">We will contact you as soon</div>
              <Row style={{ marginTop: "50px" }}>
                <Col style={{ padding: "0px" }}>
                  <div className="sliding-product-return-btn-wrapper" >
                    <div className="sliding-product-return-btn-text"
                      onClick={() => {
                        setIsThankPaneOpen(false);
                      }}
                    >
                      Return to shop
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </SlidingPane>
      </>

    );
};

export default Index;
